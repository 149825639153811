import clsx from 'clsx'
import { FormikErrors } from 'formik'
import { FC, ReactNode } from 'react'

import styles from './ErrorMessage.module.scss'

interface ErrorMessageProps {
  className?: string
  before?: ReactNode
  after?: ReactNode
  children:
    | ReactNode
    | string
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[]
    | undefined
}

const ErrorMessage: FC<ErrorMessageProps> = ({
  className = '',
  before = null,
  after = null,
  children
}) => {
  return (
    <div className={clsx(styles['error-message'], className)}>
      <>
        {before}
        {children}
        {after}
      </>
    </div>
  )
}
export default ErrorMessage
