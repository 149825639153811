import { FC, ReactElement, useEffect } from 'react'

interface FormikWithServerErrorsProps {
  children: ReactElement
  setErrors: Function
  serverErrors: Object
}
const FormikWithServerErrors: FC<FormikWithServerErrorsProps> = ({
  children,
  setErrors,
  serverErrors
}) => {
  useEffect(() => {
    if (serverErrors && setErrors) {
      setErrors(serverErrors)
    }
  }, [serverErrors, setErrors])

  return <>{children}</>
}
export default FormikWithServerErrors
