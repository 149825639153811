import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

const Captcha = React.forwardRef((props, ref) => {
  const {
    i18n: { language }
  } = useTranslation()
  const [captchaKey, setCaptchaKey] = useState<null | string>(null)

  useEffect(() => {
    setCaptchaKey(GOOGLE_CAPTCHA_SITE_KEY)
  }, [])

  if (!captchaKey) {
    return null
  }

  return (
    // @ts-ignore
    <ReCAPTCHA hl={language} ref={ref} size="invisible" sitekey={captchaKey} />
  )
})
Captcha.displayName = 'Captcha'
export default Captcha
